import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Select,
} from "@/components/ui/select";
import { TabsList, TabsTrigger, TabsContent, Tabs } from "@/components/ui/tabs";
import { Clock, Ticket, Trash2 } from "lucide-react";
import TicketCard from "./TicketCard";
import { useState } from "react";

const TicketCountCard = () => {
  return (
    <div className="border rounded-lg mb-2 w-full text-left p-2 px-4">
      <h6 className="font-medium text-md">
        Ticket Order ID: <span className="uppercase">#B84630d</span>
      </h6>
      <div className="flex justify-start items-center divide-x gap-3">
        <div className="flex items-center gap-1">
          <Ticket size={20} className="text-gray-500" />
          <p className="text-md font-normal text-gray-500">No. of Tickets: 4</p>
        </div>
        {""}
        <div className="flex items-center gap-1">
          <Clock size={20} className="text-gray-500 ms-2" />
          <p className="text-md font-normal text-gray-500">
            {" "}
            3rd Jun. 2024 - 4pm
          </p>
        </div>
      </div>
    </div>
  );
};

const ViewTicket = ({ data, event, delFunc, updateFunc, currency }: any) => {
  const [status, setStatus] = useState(data.status);

  const changeStatus = () => {
    if (data.status !== status) {
      updateFunc(data, status);
    }
  };

  return (
    <Card className="w-full p-0 border-0 shadow-none">
      <CardHeader>
        <CardTitle className="capitalize">{event.name} stats</CardTitle>
        <CardDescription>Shows detailed stats of tickets</CardDescription>
      </CardHeader>
      <CardContent>
        <TicketCard currency={currency} viewBool={true} key={1} data={data} />
        <div className="mt-3">
          <Tabs defaultValue="scanned">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="scanned">Scanned</TabsTrigger>
              <TabsTrigger value="actions">Actions</TabsTrigger>
            </TabsList>
            <TabsContent
              className="max-h-[50vh] overflow-y-scroll"
              value="scanned"
            >
              <div className="grid grid-col-3">
                {Array.from({ length: 50 }).map((ticket) => (
                  <TicketCountCard />
                ))}
              </div>
            </TabsContent>
            <TabsContent className="max-h-[50vh]" value="actions">
              <div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="maxtickets">Status</Label>
                  <Select onValueChange={(e) => setStatus(e)} value={status}>
                    <SelectTrigger id="maxtickets" className="capitalize">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent position="popper">
                      {["live", "ended", "on hold"].map((status) => (
                        <SelectItem
                          className="capitalize"
                          value={`${status}`}
                          key={status}
                        >
                          {status}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <Button className="bg-red-600" onClick={() => delFunc(data)}>
                    <Trash2 color="#fff" /> Delete Ticket
                  </Button>
                  <Button className="bg-black" onClick={changeStatus}>
                    Submit
                  </Button>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </CardContent>
    </Card>
  );
};

export default ViewTicket;
