import { Button } from "@/components/ui/button";
import { EmptyContainer } from "components/custom/EmptyState";
import { ppholder } from "constants/assets";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { CircleFadingPlus, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const StoryView = () => {
  const { showToast, closeLoading, showLoading } = useUIMisc();
  const axiosPrivate = useAxiosPrivate();
  const [stories, setStories] = useState<any[]>([]);
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );

  const handleStoryUpload = async (file: any) => {
    let formData = new FormData();
    formData.append("image", file);
    showLoading();
    try {
      const uploadImg = await axiosPrivate.put(
        `/events/stories/${accountData._id}/${dashData._id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setTimeout(() => {
        closeLoading();
        showToast(`Uploaded story successfully!`, "success");
      }, 1000);
      setStories(uploadImg.data.data.images);
    } catch (error: any) {
      setTimeout(() => {
        closeLoading();
        showToast(`An error occured!\n ${error.message}\n Try again!`, "error");
      }, 2000);
    }
  };

  const chooseImg = () => {
    const inputElem = document.createElement("input");
    inputElem.type = "file";
    inputElem.name = "image";
    inputElem.setAttribute("accept", ".jpg, .png, .jpeg");
    inputElem.click();
    inputElem.onchange = (ev: any) => {
      if (ev.target.files[0].size > 2097152) {
        showToast(`Image must be 2MB maximum!`, "warning");
      } else {
        handleStoryUpload(ev.target.files[0]);
      }
    };
  };

  const getStories = async () => {
    try {
      const storyRes = await axiosPrivate.get(
        `/events/stories/${dashData._id}`
      );
      if (storyRes.data.data.images !== undefined) {
        setStories(storyRes.data.data.images);
      }
    } catch (error: any) {
      // closeLoader();
      showToast(`${error.response.data.message}`, "error");
    }
  };

  const delPhotos = async (index: number) => {
    showLoading();
    const allImgs = [...stories];
    try {
      await axiosPrivate.delete(
        `/events/stories/${accountData._id}/${index}/${dashData._id}`
      );
      allImgs.splice(index, 1);
      setStories(allImgs);
      closeLoading();
      showToast(`Deleted story successfully!`, "success");
    } catch (error: any) {
      closeLoading();
      showToast(`${error.response.data.message}`, "error");
    }
  };

  useEffect(() => {
    if (dashData) {
      getStories();
    }
  }, []);

  return (
    <div className="w-full">
      <div className="mb-3 flex justify-end items-center ">
        <Button
          variant="outline"
          className="hover:bg-black hover:text-white"
          onClick={chooseImg}
        >
          <CircleFadingPlus className="me-2" /> Add to Story
        </Button>
      </div>
      <div className="grid grid-cols-5 gap-4">
        <>
          {stories.length !== 0 ? (
            <>
              {stories.map((image: any, index: any) => (
                <div className="w-36 h-36 rounded-lg mb-7" key={image.fileId}>
                  <img
                    src={image.url || ppholder}
                    alt=""
                    className="w-full h-full object-cover rounded-lg"
                  />
                  <div
                    className="flex justify-center  bg-slate-100 text-red-600 text-sm p-2 mt-2 rounded-md"
                    onClick={() => delPhotos(index)}
                  >
                    <Trash2 size={15} />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="col-span-5">
              <EmptyContainer
                message="No stories yet."
                instructions="Click on the upload button to add story."
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default StoryView;
