import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { BriefcaseBusiness } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { cleanDate } from "services/misc";

type CCProps = {
  viewBool: boolean;
  func?: (type: string) => void;
  data: any;
  viewFunc?: (data: any) => void;
};

const BookingCard = ({ viewBool, data }: CCProps) => {
  return (
    <Card className={`w-full p-0 border-0  ${viewBool ? "border" : "shadow"} `}>
      <CardContent className="pt-6">
        <div className="flex justify-between item-center">
          <div className="flex justify-start items-center gap-2 w-full">
            <div className="bg-gray-100 p-2 rounded-md">
              <BriefcaseBusiness />
            </div>
            <div>
              <h6 className="text-md font-medium capitalize text-black">
                {data.bookingId}
              </h6>
              <p className="font-normal text-gray-500 capitalize">
                {data.busType}
              </p>
            </div>
          </div>
          <div>
            <Badge
              className={`tracking-tighter ${
                data.status === "pending"
                  ? "bg-yellow-300 text-black"
                  : data.status === "active"
                  ? " bg-emerald-600"
                  : "bg-red-600"
              }`}
            >
              {data.status === "pending"
                ? "Pending"
                : data.status === "active"
                ? "Live"
                : "Ended"}
            </Badge>
          </div>
        </div>
        <Separator className="my-2" />
        <div className="grid grid-cols-2 divide-x">
          <div className="text-center">
            <span className="text-gray-400 text-sm">Booking Date</span>
            <h6 className="text-sm font-medium">{cleanDate(data.date)}</h6>
          </div>
          <div className="text-center">
            <span className="text-gray-400 text-sm">User Confirmation</span>
            <h6 className="text-sm font-medium">
              <Badge
                className={`tracking-tighter ${
                  data.userConfirm === "pending"
                    ? "bg-yellow-300 text-black"
                    : data.userConfirm === "completed"
                    ? " bg-emerald-600"
                    : "bg-red-600"
                }`}
              >
                {data.userConfirm === "pending"
                  ? "Pending"
                  : data.userConfirm === "completed"
                  ? "Completed"
                  : "cancelled"}
              </Badge>
            </h6>
          </div>
        </div>
        <Separator className="my-2" />
        <div className="flex justify-end items-center">
          <span className="text-sm text-gray-500 capitalize">
            {cleanDate(data.createdAt)}
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

export default BookingCard;
