import { StatsCard } from "pages/components";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../@/components/ui/dropdown-menu";
import {
  Banknote,
  ListFilter,
  QrCode,
  SwatchBook,
  Ticket,
  Users,
} from "lucide-react";
import { Button } from "../../../@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import AddTeam from "./components/AddTeam";
import ViewTeam from "./components/ViewTeam";
import TeamCard from "./components/TeamCard";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import useUIMisc from "hooks/useUIMisc";
import { uniqBy } from "lodash";
import { SkeletonCard } from "components/custom/SkeletonCards";
import { EmptyContainer } from "components/custom/EmptyState";
import SearchInput from "components/custom/SearchInput";
import { TEAM_FILTER } from "constants/data";
import { isObjEmpty } from "services/misc";
import useScrollToBottom from "hooks/useScrollToBottom";
type Props = {};

const TeamsPage = (props: Props) => {
  const axiosPrivate = useAxiosPrivate();
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const [openModal, setOpenModal] = useState(false);
  const initPageData = {
    searchPage: 1,
    searchTotal: 0,
    page: 1,
    total: 0,
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeam, setSelectedTeam] = useState<any>({});
  const initFilter = {
    roles: "all",
    status: "all",
    sort: "desc",
  };
  const [filter, setFilter] = useState(initFilter);
  const [allTeams, setAllTeams] = useState<any[]>([]);
  const [emptyState, setEmptyState] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [pageData, setPageData] = useState(initPageData);
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const initStats = {
    total: 0,
    sell: 0,
    sellScan: 0,
    scan: 0,
  };
  const [statsData, setStatsData]: any = useState(initStats);

  const getTeams = async () => {
    try {
      const teams = await axiosPrivate.get(
        `/teams/manager/${accountData._id}/${dashData._id}`,
        {
          params: {
            page: pageData.page,
            roles: filter.roles,
            status: filter.status,
          },
        }
      );
      const responseData = teams.data.data;
      setPageData({ ...pageData, total: teams.data.total });
      setAllTeams([...allTeams, ...responseData]);
      if (responseData.length === 0 && allTeams.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const viewTeamModal = (teamData: any) => {
    setActiveModal("view");
    setOpenModal(true);
    setSelectedTeam(teamData);
  };

  const openAddModal = () => {
    setActiveModal("add");
    setOpenModal(true);
  };

  const addTeam = async (data: any) => {
    const allData = [data, ...allTeams];
    setAllTeams(allData);
    setPageData({ ...pageData, total: pageData.total + 1 });
    setOpenModal(false);
  };

  const searchTeams = async (type: string, pageNum?: number) => {
    // setSearchTerm(value);
    if (searchTerm === "") {
      setAllTeams([]);
      setSearchTerm("");
      setPageData(initPageData);
    } else {
      try {
        const searchResults = await axiosPrivate.get(
          `/teams/manager/${accountData._id}/${dashData._id}/search/`,
          {
            params: {
              q: searchTerm,
              page: type === "new" ? 1 : pageNum,
            },
          }
        );

        const responseData = searchResults.data.data;
        if (type === "more") {
          setAllTeams([...allTeams, ...responseData]);
        } else {
          setAllTeams(responseData);
          setPageData({ ...pageData, searchTotal: searchResults.data.total });
          if (responseData.length === 0) {
            setEmptyState(true);
          }
        }
      } catch (error) {}
    }
  };

  const searchLoadMore = () => {
    if (pageData.searchTotal !== allTeams.length) {
      setPageData({ ...pageData, searchPage: pageData.searchPage + 1 });
      searchTeams("more", pageData.searchPage + 1);
    }
  };

  const changeFilter = (filterType: string) => {
    setSearchTerm("");
    setAllTeams([]);
    setPageData(initPageData);
    setFilter({ ...filter, roles: filterType });
  };

  const getStats = async () => {
    try {
      const statsRes = await axiosPrivate.get(
        `/teams/stats/${accountData._id}/${dashData._id}`
      );

      setStatsData(
        !isObjEmpty(statsRes.data.data) ? statsRes.data.data : initStats
      );
    } catch (error) {}
  };

  const updateStatusFunc = (data: any, type: string) => {
    if (type === "add") {
      setStatsData({
        ...statsData,
        total: statsData.total + 1,
        sell: data.roles === "sell" ? statsData.sell + 1 : statsData.sell,
        scan: data.roles === "scan" ? statsData.scan + 1 : statsData.scan,
        sellScan:
          data.roles === "sell-scan"
            ? statsData.sellScan + 1
            : statsData.sellScan,
      });
    } else if (type === "del") {
      setStatsData({
        ...statsData,
        total: statsData.total - 1,
        sell: data.roles === "sell" ? statsData.sell - 1 : statsData.sell,
        scan: data.roles === "scan" ? statsData.scan - 1 : statsData.scan,
        sellScan:
          data.roles === "sell-scan"
            ? statsData.sellScan - 1
            : statsData.sellScan,
      });
    } else {
    }
  };

  const removeTeam = (teamId: string) => {
    const mainTeams = [...allTeams];
    const filteredTeams = mainTeams.filter((t) => t._id !== teamId);
    setAllTeams(filteredTeams);
  };

  const deleteTeam = async (data: any) => {
    try {
      await axiosPrivate.delete(
        `/teams/manager/${accountData._id}/${data._id}/${dashData._id}`
      );
      updateStatusFunc(data, "del");
      removeTeam(data._id);
      setOpenModal(false);
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const updateTeam = async (data: any, updateData: any) => {
    try {
      await axiosPrivate.put(
        `/teams/manager/${accountData._id}/${updateData._id}/${dashData._id}`,
        updateData
      );
      setOpenModal(false);
      const mainAllTeam: any = [...allTeams];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const findIndex = mainAllTeam.findIndex(
        (a: any) => a._id === updateData._id
      );
      mainAllTeam[findIndex] = { ...data, ...updateData };
      setAllTeams([...mainAllTeam]);
      if (data.roles !== updateData.roles) {
        const allStats = { ...statsData };
        data.roles === "sell-scan"
          ? (allStats["sellScan"] -= 1)
          : (allStats[data.roles] -= 1);
        updateData.roles === "sell-scan"
          ? (allStats["sellScan"] += 1)
          : (allStats[updateData.roles] += 1);
        setStatsData(allStats);
      }
      showToast(`Team member updated successful`, "success");
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      getTeams();
    }
  }, [searchTerm, pageData.page, filter.roles]);

  useEffect(() => {
    if (searchTerm !== "" && searchTerm.length > 3) {
      searchTeams("new", 1);
    }
  }, [searchTerm]);

  const loadMore = () => {
    if (pageData.total !== allTeams.length) {
      setPageData({ ...pageData, page: pageData.page + 1 });
    }
  };

  const loadMoreData = () => {
    setTimeout(() => {
      if (searchTerm !== "" && searchTerm.length > 3) {
        searchLoadMore();
      } else {
        loadMore();
      }
      resetFetching();
    }, 1000);
  };

  const { isFetching, resetFetching } = useScrollToBottom(loadMoreData, 100);

  return (
    <div>
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl max-h-screen">
          {activeModal === "add" ? (
            <AddTeam addFunc={addTeam} updateFunc={updateStatusFunc} />
          ) : activeModal === "view" ? (
            <ViewTeam
              data={selectedTeam}
              delFunc={deleteTeam}
              updateFunc={updateTeam}
            />
          ) : null}
        </DialogContent>
      </Dialog>
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
        <StatsCard
          name="Total Team Members"
          figure={statsData.total}
          iconData={{ icon: Users }}
          info="Number of team"
        />
        <StatsCard
          name="Total Sale Team"
          figure={statsData.sell}
          iconData={{ icon: Banknote }}
          info="Number of sale"
        />
        <StatsCard
          name="Total Scan Team"
          figure={statsData.scan}
          iconData={{ icon: QrCode }}
          info="Number of scan"
        />
        <StatsCard
          name="Total Multi Team "
          figure={statsData.sellScan}
          iconData={{ icon: SwatchBook }}
          info="Number of both scan and sale"
        />
      </div>
      <div className="flex justify-between items-center mb-5">
        <h5 className="font-bold text-3xl">Teams</h5>
        <div className="flex justify-end mt-4 gap-3">
          <SearchInput searchFunc={setSearchTerm} />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <ListFilter /> Filter
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              {TEAM_FILTER.map((teamFilter) => (
                <DropdownMenuItem onClick={() => changeFilter(teamFilter.slug)}>
                  {teamFilter.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Button className="bg-black" onClick={openAddModal}>
            Add Team
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5">
        {allTeams !== undefined && allTeams.length !== 0 ? (
          <>
            {uniqBy(allTeams, "_id").map((team) => (
              <>
                <TeamCard
                  viewBool={false}
                  data={team}
                  viewFunc={viewTeamModal}
                />
              </>
            ))}
          </>
        ) : allTeams.length === 0 && !emptyState ? (
          <>
            {Array.from({ length: 4 }).map((card) => (
              <SkeletonCard />
            ))}
          </>
        ) : (
          <div className="col-span-3">
            <EmptyContainer
              message={"No team member yet!"}
              instructions={`Click the "Add" button to create one`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamsPage;
