import * as yup from 'yup';
declare module 'yup' {
    interface StringSchema {
        noScientificNotation(message?: string): this;
        noOnlySymbolsOrNumbers(message?: string): this;
    }
}

yup.addMethod(yup.string, 'noScientificNotation', function (message) {
    return this.test('no-scientific-notation', message, function (value) {
        const { path, createError } = this;
        const isScientificNotation = /[eE]/.test(value || '');
        return !isScientificNotation || createError({ path, message: message || 'No scientific notation allowed' });
    });
});


yup.addMethod(yup.string, 'noOnlySymbolsOrNumbers', function (message) {
    return this.test('no-only-symbols-or-numbers', message, function (value) {
        const { path, createError } = this;
        const hasAlphabet = /^[A-Za-z].*$/.test(value || '');
        const isOnlySymbolsOrNumbers = /^[\d\s.,'–\-]*$/.test(value || '');
        return hasAlphabet || createError({ path, message: message || 'Description must contain at least one letter and cannot be only symbols or numbers' });
    });
});

const usernamePattern = /^@[a-zA-Z0-9._]+$/;
const formValidations = {
    email: yup.string().email("Invalid email").required("Required"),
    name: yup
        .string()
        .min(3, "Must be at least 3 characters")
        .max(50, "Must be at most 50 characters")
        .matches(/^[A-Za-z]+$/, "Must contain only letters")
        .required("Required"),
    password: yup
        .string()
        .min(8, "Must be at least 8 characters")
        .matches(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
            "Must contain minimum 8 characters long, at least one uppercase letter, one lowercasse letter, one number & a special character"
        )
        .required("Required"),
    shortdesc: yup.string().min(10, 'Must be at least 10 characters').max(100, 'Must be 100 characters long').required("Required")
        .matches(/^[A-Za-z][A-Za-z\s.,'–-]*$/, 'Description must start with a letter and can only contain alphabets, spaces, dots, commas, apostrophes, hyphens, and dashes')
        .noOnlySymbolsOrNumbers('Description must contain at least one letter and cannot be only symbols or numbers'),
    // desc: yup.string().min(10, 'Must be at least 10 characters').max(1024, 'Must be 1024 characters long').required("Required")
    //     .matches(/^[A-Za-z][A-Za-z\s.,'–-]*$/, 'Description must start with a letter and can only contain alphabets, spaces, dots, commas, apostrophes, hyphens, and dashes')
    //     .noOnlySymbolsOrNumbers('Description must contain at least one letter and cannot be only symbols or numbers'),
    desc: yup.string().min(10, 'Must be at least 10 characters').max(1024, 'Must be 1024 characters long')
        .matches(/^[A-Za-z]/, 'Description must start with a letter')
        .matches(/[^0-9]+/, 'Description cannot contain only numbers')
        // .test('symbol-spacing', 'Too many symbols in a row', (value: any) => {
        //     // This test checks that symbols are not inline (more than 3 in a row)
        //     return !/[\W_]{4,}/.test(value);
        // })
        .min(1, 'Description must contain at least one letter')
        .required('Description is required'),
    bio: yup.string().min(10, 'Must be at least 10 characters').max(100, 'Must be 100 characters long')
        .matches(/^[A-Za-z]/, 'Bio must start with a letter')
        .matches(/[^0-9]+/, 'Bio cannot contain only numbers')
        .test('symbol-spacing', 'Too many symbols in a row', (value: any) => {
            // This test checks that symbols are not inline (more than 3 in a row)
            return !/[\W_]{4,}/.test(value);
        })
        .min(1, 'Bio must contain at least one letter')
        .required('Bio is required'),
    address: yup.string().min(3, 'Must be at least 3 characters').max(200, 'Must be 200 characters long').required("Required"),
    link: yup.string()
        .url('Invalid URL format')
        .matches(/^https:\/\//, 'URL must start with "https://"'),
    link_required: yup.string()
        .url('Invalid URL format')
        .matches(/^https:\/\//, 'URL must start with "https://"')
        .required('URL is required'),
    default: yup.string().required("Required"),
    defaultString: yup.string().required("Required")
        .min(3, 'Name must be at least 3 characters')
        .max(30, 'Name must be at most 30 characters')
        .matches(
            /^[A-Za-z][A-Za-z\s.,'’-]*$/,
            "Name must start with a letter and can only contain alphabets, spaces, dots, commas, apostrophes, hyphens, or dashes"
        ),
    defaultText: yup.string().required("Required")
        .min(3, 'Name must be at least 3 characters')
        .max(200, 'Name must be at most 200 characters')
        .matches(
            /^[A-Za-z][A-Za-z\s.,'’-]*$/,
            "Name must start with a letter and can only contain alphabets, spaces, dots, commas, apostrophes, hyphens, or dashes"
        ),
    defaultAccText: yup.string().required("Required")
        .min(3, 'Name must be at least 3 characters')
        .max(200, 'Name must be at most 200 characters'),
    accountNumber: yup.string()
        .matches(/^[0-9]+$/, "Account number must be only digits")
        .min(10, "Account number must be at least 10 digits")
        .max(16, "Account number must be exactly 16 digits")
        .required("Account number is required"),
    // defaultNum: yup.number().notOneOf([0], 'Number must not be 0').required("Required"),
    defaultNum: yup.string().required("Required").matches(/^\d+$/, 'Only digits are allowed')
        .noScientificNotation('Scientific notation is not allowed')
        .test('not-zero', 'Number must not be zero', (value) => Number(value) !== 0)
        .test('min', 'Number must be at least 1', (value) => Number(value) >= 1)
        .test('max', 'Number must be at most 1,000,000', (value) => Number(value) <= 1000000),
    defaultAmount: yup.string().required("Required").matches(/^\d+$/, 'Only digits are allowed')
        .noScientificNotation('Scientific notation is not allowed')
        .test('not-zero', 'Number must not be zero', (value) => Number(value) !== 0)
        .test('min', 'Number must be at least 100', (value) => Number(value) >= 100),
    // .test('max', 'Number must be at most 1,000,000', (value) => Number(value) <= 1000000),
    defaultArr: yup.mixed().required("Required"),
    username: yup.string().matches(usernamePattern, {
        message: "Username must start with '@' and can only contain letters, numbers, underscores ('_'), and periods ('.')",
        excludeEmptyString: true
    }),
    confirmPassword: yup.string().required()
        .oneOf([yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
    datetime: yup.string()
        .required('Date is required'),
    main_name: yup
        .string()
        .min(3, "Must be at least 3 characters")
        .max(50, "Must be at most 50 characters")
        .matches(/^[a-zA-Z ]+$/, "Must contain only letters")
        .required("Required"),
    price: yup.string().when('ticketType', (ticketType, schema) => {
        if (ticketType[0] === 'free') {
            return schema
                .notRequired()
                .test('is-zero', 'Price must be 0 for free tickets', (value) => value === '0' || value === '');
        } else {
            return schema
                .required("Price is required")
                .matches(/^\d+$/, 'Only digits are allowed')
                .noScientificNotation('Scientific notation is not allowed')
                .test('not-zero', 'Number must not be zero', (value) => Number(value) !== 0)
                .test('min', 'Number must be at least 1', (value) => Number(value) >= 1)
                .test('max', 'Number must be at most 1,000,000', (value) => Number(value) <= 1000000);
        }
    })
}

export {
    formValidations
}