const EVENTS_TYPE = [
    { icon_name: '🏟️', label: 'Sports', value: 'sports' },
    { icon_name: '🏆', label: 'Tournaments', value: 'tournaments' },
    { icon_name: '🎤', label: 'Concert', value: 'concert' },
    { icon_name: '🖼️', label: 'Exhibition', value: 'exhibition' },
    { icon_name: '🗺️', label: 'Trip', value: 'trip' },
    { icon_name: '💼', label: 'Conference', value: 'conference' },
    { icon_name: '🎉', label: 'Festival', value: 'festival' },
    { icon_name: '📚', label: 'Workshop', value: 'workshop' },
    { icon_name: '🍷', label: 'Dinner', value: 'dinner' },
    { icon_name: '🍻', label: 'Party', value: 'party' },
];

const SUITABLE_FOR = [
    { label: "Anniversary", value: "Anniversary", slug: "anniversary" },
    { label: "Auditions", value: "Auditions", slug: "auditions" },
    { label: "Awards", value: "Awards", slug: "awards" },
    { label: "Birthday", value: "Birthday", slug: "birthday" },
    { label: "Bridal", value: "Bridal", slug: "bridal" },
    { label: "Celebration", value: "Celebration", slug: "celebration" },
    { label: "Christmas", value: "Christmas", slug: "christmas" },
    { label: "Church", value: "Church", slug: "church" },
    { label: "Cocktail", value: "Cocktail", slug: "cocktail" },
    { label: "Concert", value: "Concert", slug: "concert" },
    { label: "Conference", value: "Conference", slug: "conference" },
    { label: "Engagement", value: "Engagement", slug: "engagement" },
    { label: "Exhibition", value: "Exhibition", slug: "exhibition" },
    { label: "Experience", value: "Experience", slug: "experience" },
    { label: "Fashion", value: "Fashion", slug: "fashion" },
    { label: "Funeral", value: "Funeral", slug: "funeral" },
    { label: "Graduation", value: "Graduation", slug: "graduation" },
    { label: "Lectures", value: "Lectures", slug: "lectures" },
    { label: "Meeting", value: "Meeting", slug: "meeting" },
    { label: "Naming", value: "Naming", slug: "naming" },
    { label: "Party", value: "Party", slug: "party" },
    { label: "Photo/Film", value: "Photo/Film", slug: "photo-film" },
    { label: "Press", value: "Press", slug: "press" },
    { label: "Recreation", value: "Recreation", slug: "recreation" },
    { label: "Seminar", value: "Seminar", slug: "seminar" },
    { label: "Symposium", value: "Symposium", slug: "symposium" },
    { label: "Training", value: "Training", slug: "training" },
    { label: "Wedding", value: "Wedding", slug: "wedding" },
    { label: "Workshop", value: "Workshop", slug: "workshop" }
]


const VENUE_TYPES = [
    { label: "Arena", value: "Arena", slug: "arena", icon: "" },
    { label: "Auditorium", value: "Auditorium", slug: "auditorium", icon: "" },
    { label: "Ballroom", value: "Ballroom", slug: "ballroom", icon: "" },
    { label: "Banquet", value: "Banquet", slug: "banquet", icon: "" },
    { label: "Bar", value: "Bar", slug: "bar", icon: "" },
    { label: "Beach", value: "Beach", slug: "beach", icon: "" },
    { label: "Boardroom", value: "Boardroom", slug: "boardroom", icon: "" },
    { label: "Cafe", value: "Cafe", slug: "cafe", icon: "" },
    { label: "Classroom", value: "Classroom", slug: "classroom", icon: "" },
    { label: "Community", value: "Community", slug: "community", icon: "" },
    { label: "Conference", value: "Conference", slug: "conference", icon: "" },
    { label: "Coworking", value: "Coworking", slug: "coworking", icon: "" },
    { label: "Dining", value: "Dining", slug: "dining", icon: "" },
    { label: "Dome", value: "Dome", slug: "dome", icon: "" },
    { label: "Field", value: "Field", slug: "field", icon: "" },
    { label: "Gallery", value: "Gallery", slug: "gallery", icon: "" },
    { label: "Garden", value: "Garden", slug: "garden", icon: "" },
    { label: "Generator", value: "Generator", slug: "generator", icon: "" },
    { label: "Hotel", value: "Hotel", slug: "hotel", icon: "" },
    { label: "Indoor", value: "Indoor", slug: "indoor", icon: "" },
    { label: "Lawn", value: "Lawn", slug: "lawn", icon: "" },
    { label: "Lighting", value: "Lighting", slug: "lighting", icon: "" },
    { label: "Lounge", value: "Lounge", slug: "lounge", icon: "" },
    { label: "Marquee", value: "Marquee", slug: "marquee", icon: "" },
    { label: "Meeting", value: "Meeting", slug: "meeting", icon: "" },
    { label: "Multipurpose", value: "Multipurpose", slug: "multipurpose", icon: "" },
    { label: "Nightclub", value: "Nightclub", slug: "nightclub", icon: "" },
    { label: "Outdoor", value: "Outdoor", slug: "outdoor", icon: "" },
    { label: "Park", value: "Park", slug: "park", icon: "" },
    { label: "Poolside", value: "Poolside", slug: "poolside", icon: "" },
    { label: "Residence", value: "Residence", slug: "residence", icon: "" },
    { label: "Resort", value: "Resort", slug: "resort", icon: "" },
    { label: "Restaurant", value: "Restaurant", slug: "restaurant", icon: "" },
    { label: "Rooftop", value: "Rooftop", slug: "rooftop", icon: "" },
    { label: "Terrace", value: "Terrace", slug: "terrace", icon: "" },
    { label: "Theater", value: "Theater", slug: "theater", icon: "" },
    { label: "Unique", value: "Unique", slug: "unique", icon: "" },
    { label: "Worship", value: "Worship", slug: "worship", icon: "" }
]


const AMENITIES = [
    { label: "Accommodation", value: "Accommodation", slug: "accommodation", icon: "hotel" },
    { label: "Air Conditioning", value: "Air Conditioning", slug: "air-conditioning", icon: "air-conditioner" },
    { label: "AV Equipment", value: "AV Equipment", slug: "av-equipment", icon: "projector" },
    { label: "Catering", value: "Catering", slug: "catering", icon: "fork-knife" },
    { label: "CCTV", value: "CCTV", slug: "cctv", icon: "security-camera" },
    { label: "Changing Rooms", value: "Changing Rooms", slug: "changing-rooms", icon: "changing-room" },
    { label: "Conference Call", value: "Conference Call", slug: "conference-call", icon: "phone" },
    { label: "Decor", value: "Decor", slug: "decor", icon: "paint-brush" },
    { label: "Disability Access", value: "Disability Access", slug: "disability-access", icon: "accessibility" },
    { label: "Electricians", value: "Electricians", slug: "electricians", icon: "wrench" },
    { label: "Equipment Rentals", value: "Equipment Rentals", slug: "equipment-rentals", icon: "rental-car" },
    { label: "Event Planning", value: "Event Planning", slug: "event-planning", icon: "calendar" },
    { label: "Displays", value: "Displays", slug: "displays", icon: "monitor" },
    { label: "Flip-charts", value: "Flip-charts", slug: "flip-charts", icon: "clipboard" },
    { label: "Lighting", value: "Lighting", slug: "lighting", icon: "lightbulb" },
    { label: "Materials", value: "Materials", slug: "materials", icon: "box" },
    { label: "Kitchen", value: "Kitchen", slug: "kitchen", icon: "kitchen" },
    { label: "PA System", value: "PA System", slug: "pa-system", icon: "microphone" },
    { label: "Parking", value: "Parking", slug: "parking", icon: "parking" },
    { label: "Podium", value: "Podium", slug: "podium", icon: "podium" },
    { label: "Printing", value: "Printing", slug: "printing", icon: "printer" },
    { label: "Projector", value: "Projector", slug: "projector", icon: "projector" },
    { label: "Transport", value: "Transport", slug: "transport", icon: "bus" },
    { label: "Security", value: "Security", slug: "security", icon: "shield" },
    { label: "Sound System", value: "Sound System", slug: "sound-system", icon: "speaker" },
    { label: "Stage", value: "Stage", slug: "stage", icon: "stage" },
    { label: "Generator", value: "Generator", slug: "generator", icon: "plug" },
    { label: "Furniture", value: "Furniture", slug: "furniture", icon: "chair" },
    { label: "Tents", value: "Tents", slug: "tents", icon: "tent" },
    { label: "Video Conferencing", value: "Video Conferencing", slug: "video-conferencing", icon: "video-camera" },
    { label: "Washrooms", value: "Washrooms", slug: "washrooms", icon: "toilet" },
    { label: "WiFi", value: "WiFi", slug: "wifi", icon: "wifi" }
];

const INTERESTS = [
    {
        value: 'Travel',
        label: 'Travel',
        icon_name: '✈️',
        suggestions: [
            'wanderlust', 'adventure', 'travelgram', 'explore', 'vacation',
            'traveller', 'tourism', 'nature', 'roadtrip', 'backpacking',
            'destination', 'holiday', 'passport', 'travelphotography', 'beach',
            'sunset', 'cityscape', 'jetsetter', 'mountains', 'travelblogger',
            'getaway', 'culture', 'islandlife', 'globetrotter', 'bucketlist',
            'solotravel', 'landscape', 'exploremore', 'traveladdict', 'instatravel',
            'traveling', 'flight', 'airplane', 'hotel', 'worldtravel',
            'vacay', 'trip', 'nomad', 'seetheworld', 'holidayvibes',
            'scenic', 'adventureawaits', 'epicviews', 'exploration', 'roamtheplanet',
            'traveldiary', 'traveldeeper', 'lovetotravel', 'globetrekking', 'hikingadventures'
        ]
    },
    {
        value: 'Photography',
        label: 'Photography',
        icon_name: '📸',
        suggestions: [
            'photooftheday', 'photographer', 'instaphoto', 'picoftheday', 'naturephotography',
            'portrait', 'landscapephotography', 'canon', 'nikon', 'dslr',
            'streetphotography', 'blackandwhite', 'travelphotography', 'wildlifephotography', 'sunsetphotography',
            'portraitphotography', 'aesthetic', 'lightroom', 'photographylover', 'photoshoot',
            'photographylovers', 'lens', 'focus', 'shutterbug', 'capture',
            'instaphotography', 'visualart', 'naturelovers', 'photographyislife', 'photographyeveryday',
            'creativephotography', 'cameragear', 'artofvisuals', 'instapic', 'fineartphotography',
            'pictureoftheday', 'digitalphotography', 'landscapelovers', 'streetphoto', 'art',
            'beauty', 'fashionphotography', 'photo', 'snapseed', 'instadaily',
            'visuals', 'captured', 'filmmaking', 'cinematography', 'portraitmood'
        ]
    },
    {
        value: 'Fitness & Wellness',
        label: 'Fitness & Wellness',
        icon_name: '🏋️‍♂️',
        suggestions: [
            'fitfam', 'fitnessmotivation', 'workout', 'fitspo', 'gym',
            'health', 'exercise', 'fitlife', 'wellness', 'fitnessjourney',
            'getfit', 'yoga', 'healthylifestyle', 'cardio', 'nutrition',
            'strengthtraining', 'gymtime', 'personaltrainer', 'cleaneating', 'selfcare',
            'weightloss', 'wellbeing', 'fit', 'bodybuilding', 'crossfit',
            'fitnessgoals', 'noexcuses', 'trainhard', 'strong', 'instafit',
            'lifestyle', 'muscle', 'fitnessaddict', 'motivation', 'running',
            'gymlife', 'healthandfitness', 'nutritiontips', 'wellnessjourney', 'fitlifechoice',
            'active', 'mindfulness', 'fitspiration', 'fitfood', 'healthychoices',
            'stayhealthy', 'fitnesscommunity', 'gymrat', 'train', 'hiitworkout'
        ]
    },
    {
        value: 'Food & Drink',
        label: 'Food & Drink',
        icon_name: '🍕',
        suggestions: [
            'foodie', 'foodporn', 'yum', 'delicious', 'instafood',
            'foodstagram', 'foodphotography', 'foodlover', 'yummy', 'eat',
            'dinner', 'lunch', 'breakfast', 'snack', 'foodblogger',
            'cooking', 'homemade', 'recipe', 'chef', 'wine',
            'foodies', 'dessert', 'eating', 'tasty', 'instagood',
            'healthyfood', 'foodgram', 'foodlover', 'drinks', 'cocktail',
            'coffee', 'restaurant', 'bbq', 'cheflife', 'baking',
            'fooddiary', 'foodlove', 'comfortfood', 'sweettooth', 'foodaddict',
            'gourmet', 'instayum', 'nomnom', 'chefmode', 'homecooking',
            'foodoftheday', 'foodart', 'culinary', 'buzzfeast', 'foodheaven'
        ]
    },
    {
        value: 'Music',
        label: 'Music',
        icon_name: '🎵',
        suggestions: [
            'musiclover', 'musician', 'instamusic', 'musicislife', 'musicvideo',
            'newmusic', 'guitar', 'dj', 'hiphop', 'livemusic',
            'singersongwriter', 'producer', 'song', 'bands', 'rock',
            'jazz', 'edm', 'rap', 'musicproduction', 'instagood',
            'sound', 'vinyl', 'lyrics', 'spotify', 'musicfestival',
            'pop', 'love', 'soul', 'rockmusic', 'indie',
            'soundcloud', 'rnb', 'instamusic', 'musica', 'remix',
            'concert', 'musiclife', 'headphones', 'electronic', 'musicphotography',
            'musicianlife', 'instamusician', 'musicmood', 'tunes', 'singing',
            'songwriter', 'guitarist', 'bandsintown', 'acoustic', 'bass'
        ]
    },
    {
        value: 'Fashion',
        label: 'Fashion',
        icon_name: '👗',
        suggestions: [
            'fashionista', 'style', 'ootd', 'fashionblogger', 'instafashion',
            'fashionstyle', 'outfitoftheday', 'streetstyle', 'fashiongram', 'fashionblog',
            'stylish', 'trend', 'fashionweek', 'model', 'couture',
            'chic', 'runway', 'designer', 'moda', 'streetfashion',
            'fashiondaily', 'styleinspiration', 'ootdfashion', 'vintage', 'wardrobe',
            'fashionaddict', 'fashiondiaries', 'fashionlover', 'fashionphotography', 'trendy',
            'lookoftheday', 'styleblogger', 'whatiwore', 'fashioninsta', 'outfit',
            'fashionista', 'styleoftheday', 'glam', 'lookbook', 'luxury',
            'fashiondesigner', 'streetwear', 'fashionicon', 'modeling', 'instastyle',
            'dapper', 'styleblog', 'editorial', 'inspiration', 'sneakerhead'
        ]
    },
    {
        value: 'Art & Design',
        label: 'Art & Design',
        icon_name: '🎨',
        suggestions: [
            'art', 'artist', 'drawing', 'design', 'sketch',
            'illustration', 'painting', 'artwork', 'digitalart', 'creative',
            'instaart', 'artoftheday', 'modernart', 'instaartist', 'artistic',
            'graphicdesign', 'fineart', 'artsy', 'contemporaryart', 'abstract',
            'drawingoftheday', 'arte', 'illustrator', 'architecture', 'designinspiration',
            'artgallery', 'artlovers', 'color', 'creativity', 'streetart',
            'artcollector', 'artstudio', 'pencil', 'sculpture', 'arts',
            'artistsoninstagram', 'digitalillustration', 'visualart', 'watercolor', 'handmade',
            'creativeprocess', 'instart', 'artoftheday', 'designers', 'draw',
            'posterdesign', 'popart', 'artsy', 'gallery', 'sketchbook'
        ]
    },
    {
        value: 'Technology',
        label: 'Technology',
        icon_name: '💻',
        suggestions: [
            'tech', 'technology', 'innovation', 'gadgets', 'electronics',
            'coding', 'programming', 'developer', 'software', 'technews',
            'ai', 'artificialintelligence', 'computers', 'robotics', 'machinelearning',
            'webdevelopment', 'techy', 'startups', 'engineering', 'appdevelopment',
            'geek', 'science', 'futuristic', 'innovation', 'digital',
            'gadget', 'mobile', 'smartphone', 'apple', 'android',
            'codinglife', 'techgeek', 'nerd', 'techlovers', 'hacking',
            'cybersecurity', 'electronics', 'datatech', 'futuretech', 'it',
            'cloudcomputing', 'techsavvy', 'technologytrends', 'innovation', 'webdev',
            'softwareengineering', 'gadgetlover', 'internet', 'programmerlife', 'automation'
        ]
    },
    {
        value: 'DIY & Crafts',
        label: 'DIY & Crafts',
        icon_name: '🛠️',
        suggestions: [
            'diy', 'crafts', 'handmade', 'crafting', 'doityourself',
            'homedecor', 'diycrafts', 'diyprojects', 'diyideas', 'craft',
            'artsandcrafts', 'diyhome', 'crafty', 'handcrafted', 'diylife',
            'upcycle', 'repurpose', 'diydecor', 'diyhomedecor', 'craftymama',
            'homemade', 'diyinspiration', 'craftiness', 'diyskills', 'diytips',
            'makersgonnamake', 'creative', 'craftingideas', 'craftyhands', 'diywedding',
            'craftroom', 'handmadeisbetter', 'diylover', 'craftylife', 'craftiness',
            'diyer', 'craftlife', 'diycrafting', 'upcycling', 'craftymom',
            'craftaholic', 'diyhomeprojects', 'sewing', 'crochet', 'knitting',
            'diytools', 'craftylady', 'diylove', 'diygifts', 'craftprojects'
        ]
    },
    {
        value: 'Books & Literature',
        label: 'Books & Literature',
        icon_name: '📚',
        suggestions: [
            'booklover', 'bookstagram', 'bookworm', 'bookish', 'bibliophile',
            'reading', 'bookaddict', 'instabook', 'literature', 'read',
            'booknerd', 'bookshelf', 'bookclub', 'igreads', 'amreading',
            'booklove', 'goodreads', 'reader', 'novel', 'epicreads',
            'bookcommunity', 'author', 'bookrecommendation', 'booksbooksbooks', 'bookblogger',
            'booklovers', 'bookphotography', 'booksofinstagram', 'bookobsessed', 'currentlyreading',
            'readersofinstagram', 'bookstack', 'story', 'fiction', 'nonfiction',
            'bookquotes', 'readinglist', 'literary', 'classics', 'bookishfeatures',
            'bookaholic', 'literaryquotes', 'historicalfiction', 'fantasybooks', 'bookstore',
            'bookloverlife', 'poetry', 'paperback', 'bestseller', 'bookhaul'
        ]
    },
    {
        value: 'Movies & TV Shows',
        label: 'Movies & TV Shows',
        icon_name: '🎬',
        suggestions: [
            'movie', 'film', 'cinema', 'hollywood', 'netflix',
            'movienight', 'films', 'actor', 'moviescenes', 'movielover',
            'director', 'filmmaking', 'instamovie', 'moviebuff', 'cinephile',
            'filmmaker', 'blockbuster', 'oscars', 'actionmovie', 'filmphotography',
            'tvshows', 'tvseries', 'streaming', 'moviequotes', 'filmcommunity',
            'movieposter', 'television', 'watching', 'popcorn', 'scenes',
            'movietime', 'moviescenes', 'tvaddict', 'filmlover', 'comedy',
            'moviebuffs', 'thriller', 'horror', 'filmlovers', 'indiefilm',
            'moviestar', 'flicks', 'filming', 'moviemaking', 'instaflick',
            'tvshow', 'moviesuggestion', 'screenplay', 'cinematography', 'movielovers'
        ]
    },
    {
        value: 'Sports',
        label: 'Sports',
        icon_name: '⚽',
        suggestions: [
            'sports', 'football', 'soccer', 'basketball', 'athlete',
            'sportslife', 'training', 'fitness', 'baseball', 'workout',
            'sportsnews', 'hockey', 'running', 'motivation', 'athletics',
            'sportsphotography', 'gym', 'sportswear', 'sportsfan', 'rugby',
            'tennis', 'soccerlife', 'biker', 'team', 'gameday',
            'nfl', 'nba', 'soccerplayer', 'boxing', 'bmx',
            'sport', 'cyclist', 'swimming', 'fit', 'sportsinjury',
            'marathon', 'sportslover', 'sportsman', 'snowboarding', 'volleyball',
            'trainingday', 'instasport', 'sportsaddict', 'sportsgear', 'sportsgram',
            'sportswomen', 'athleticwear', 'adrenaline', 'racquet', 'golfer'
        ]
    },
    {
        value: 'Gaming',
        label: 'Gaming',
        icon_name: '🎮',
        suggestions: [
            'gaming', 'gamer', 'videogames', 'gamers', 'xbox',
            'playstation', 'ps4', 'gamingcommunity', 'gamergirl', 'pcgaming',
            'game', 'twitch', 'esports', 'gameon', 'gamerlife',
            'nintendo', 'streaming', 'gamestreaming', 'gaminglife', 'gamingsetup',
            'retrogaming', 'gamingpc', 'videogameaddict', 'gamerforever', 'instagamer',
            'videogame', 'fps', 'gamingpost', 'gamestagram', 'gamingdaily',
            'onlinegaming', 'gameplay', 'console', 'gaminggear', 'fortnite',
            'gamerlove', 'arcade', 'stream', 'instagaming', 'gameaddict',
            'xboxone', 'gamingroom', 'gamevideo', 'pcgamer', 'gamerfun',
            'gaminglife', 'videogamesforall', 'playstation5', 'games', 'gamersunite'
        ]
    },
    {
        value: 'Home Decor',
        label: 'Home Decor',
        icon_name: '🛋️',
        suggestions: [
            'homedecor', 'interiordesign', 'homestyle', 'decor', 'interiors',
            'homedesign', 'homedecoration', 'housebeautiful', 'homeinspiration', 'decorinspo',
            'homedecorating', 'instahome', 'homesweethome', 'interiordecor', 'home',
            'homerenovation', 'homestaging', 'homestyling', 'livingroom', 'bedroom',
            'decoration', 'diyhomedecor', 'homedesignideas', 'interiorstyling', 'dreamhome',
            'homeinterior', 'decorideas', 'interiorinspiration', 'apartmentdecor', 'homestyledecor',
            'interiordecorating', 'cozyhome', 'modernhome', 'homedetails', 'decorlover',
            'decorationideas', 'hometrends', 'homeandgarden', 'designinspiration', 'homeimprovement',
            'decors', 'decoratingideas', 'homeinteriors', 'homeideas', 'decorhome',
            'diydecor', 'instadecor', 'homeaccents', 'homemakeover', 'homedecorlove'
        ]
    },
    {
        value: 'Nature & Outdoors',
        label: 'Nature & Outdoors',
        icon_name: '🌲',
        suggestions: [
            'nature', 'outdoors', 'hiking', 'wildlife', 'naturelovers',
            'camping', 'adventure', 'mountains', 'landscape', 'naturephotography',
            'explore', 'travel', 'wilderness', 'naturelover', 'outdoorlife',
            'naturegram', 'earth', 'trekking', 'nature_perfection', 'mothernature',
            'natureseekers', 'hikingadventures', 'explorenature', 'wildlifephotography', 'nature_shooters',
            'naturelover', 'forest', 'trail', 'exploremore', 'wildernessculture',
            'optoutside', 'naturenow', 'naturephotography', 'hikinglife', 'outdoorliving',
            'adventuretime', 'naturelove', 'getoutside', 'naturalbeauty', 'naturewalk',
            'staywild', 'naturewonder', 'mountainlife', 'wildernessculture', 'wildlifeplanet',
            'outdoorgear', 'hike', 'trek', 'natureisbeautiful', 'naturevibes'
        ]
    },
    {
        value: 'Health & Nutrition',
        label: 'Health & Nutrition',
        icon_name: '🥗',
        suggestions: [
            'health', 'nutrition', 'wellness', 'healthyfood', 'fitness',
            'healthylifestyle', 'organic', 'cleaneating', 'healthyeating', 'diet',
            'nutritiontips', 'healthychoices', 'wellbeing', 'weightloss', 'nutritious',
            'healthymind', 'eatclean', 'superfoods', 'healthyhabits', 'plantbased',
            'nutritioncoach', 'fitnessjourney', 'healthytips', 'foodasmedicine', 'healthysnacks',
            'nutritionist', 'nutritioncoach', 'healthfood', 'healthyrecipes', 'eatyourveggies',
            'balancedlife', 'nutritional', 'eatwell', 'wellnessjourney', 'organicfood',
            'healthyliving', 'nutrientdense', 'healthcoach', 'wellnesscoach', 'nutritionmatters',
            'fitnessgoals', 'nutritionplan', 'healthandwellness', 'healthjourney', 'healthandfitness',
            'eatingclean', 'healthyfats', 'healthybalance', 'wholefoods', 'healthylife'
        ]
    },
    {
        value: 'Pets & Animals',
        label: 'Pets & Animals',
        icon_name: '🐾',
        suggestions: [
            'pets', 'animals', 'petstagram', 'dog', 'cat',
            'dogs', 'cats', 'dogsofinstagram', 'catsofinstagram', 'petsagram',
            'animallovers', 'petsofinstagram', 'animalsco', 'instapet', 'puppylove',
            'doglovers', 'petlove', 'ilovemydog', 'doglife', 'catlovers',
            'furryfriends', 'petlover', 'petcare', 'doglover', 'animalphotography',
            'puppy', 'cutepets', 'petpics', 'catloversclub', 'petsofig',
            'animal', 'animalrescue', 'dogloversclub', 'catlife', 'animallover',
            'doglifestyle', 'petfriends', 'dogmom', 'puppylover', 'instadog',
            'doggystyle', 'catstagram', 'dogowners', 'animalkingdom', 'petphotography',
            'cuteanimals', 'dogsandcats', 'petsofinstagram', 'puppystagram', 'animallovers'
        ]
    },
    {
        value: 'Beauty & Makeup',
        label: 'Beauty & Makeup',
        icon_name: '💄',
        suggestions: [
            'beauty', 'makeup', 'skincare', 'beautyblogger', 'cosmetics',
            'makeuplover', 'beautycare', 'beautytips', 'makeupartist', 'makeupaddict',
            'beautycommunity', 'makeuplovers', 'makeupjunkie', 'skincareroutine', 'makeupgoals',
            'makeupforever', 'beautyguru', 'beautyinfluencer', 'makeupideas', 'beautyinspo',
            'beautyvlogger', 'makeuppro', 'makeupobsessed', 'makeupmess', 'beautyproducts',
            'beautyblog', 'beautyessentials', 'makeuplife', 'beautyroutine', 'beautyobsessed',
            'makeuphaul', 'beautylover', 'beautyaddict', 'makeupporn', 'makeupcollection',
            'skincareaddict', 'makeuplife', 'makeuptutorial', 'beautyinspiration', 'makeupdaily',
            'beautyproduct', 'skincarejunkie', 'beautyhaul', 'beautyglam', 'makeupobsession',
            'makeuptips', 'beautyreview', 'makeupstash', 'beautytrends', 'makeupproducts'
        ]
    },
    {
        value: 'Entrepreneurship',
        label: 'Entrepreneurship',
        icon_name: '💼',
        suggestions: [
            'entrepreneur', 'business', 'startup', 'success', 'entrepreneurlife',
            'smallbusiness', 'entrepreneurship', 'businessowner', 'hustle', 'motivation',
            'entrepreneurs', 'businessman', 'businesswoman', 'entrepreneurmindset', 'workhard',
            'startupbusiness', 'startuplife', 'businesstips', 'entrepreneurmindset', 'businessgoals',
            'bosslife', 'entrepreneurlifestyle', 'startupstories', 'entrepreneursofinstagram', 'businessgrowth',
            'entrepreneurinspiration', 'startupfounder', 'smallbiz', 'startupgrind', 'entrepreneurmind',
            'businessownerlife', 'entrepreneurjourney', 'businesscoaching', 'startupideas', 'entrepreneurtips',
            'startupsuccess', 'entrepreneurmind', 'entrepreneurtips', 'entrepreneurmindset', 'entrepreneurinspiration',
            'startuplifestyle', 'startupsuccess', 'entrepreneurgoals', 'entrepreneurialspirit', 'startupgrind',
            'entrepreneurshipsuccess', 'smallbusinessowner', 'smallbusinesslife', 'entrepreneurdaily', 'entrepreneurmotivation'
        ]
    },
    {
        value: 'Science & Space Exploration',
        label: 'Science & Space Exploration',
        icon_name: '🔭',
        suggestions: [
            'science', 'space', 'astronomy', 'nasa', 'astrophotography',
            'universe', 'scienceisawesome', 'cosmos', 'physics', 'stars',
            'spacelovers', 'sciencefacts', 'astrophysics', 'exploretheuniverse', 'sciencerules',
            'spacex', 'astro', 'scienceworld', 'outerspace', 'cosmology',
            'galaxy', 'scientist', 'scienceexperiment', 'sciencegeek', 'scienceandspace',
            'milkyway', 'stargazing', 'spaceexploration', 'astroscience', 'spaceadventures',
            'rocket', 'spacefacts', 'scientific', 'universeandbeyond', 'scienceexplorer',
            'spacephotos', 'scienceiscool', 'sciencefun', 'spacetechnology', 'spacegeek',
            'universeexplorer', 'astronomer', 'scienceeducation', 'spacetravel', 'scienceisfun',
            'scienceandtech', 'explorespace', 'spaceengineer', 'spaceisawesome', 'cosmicexplorer'
        ]
    }
];


const DEFAULT_FILTER = [
    {
        name: 'Newest',
        slug: 'desc'
    },
    {
        name: 'Oldest',
        slug: 'asc'
    },
]
const EVENT_FILTER = [
    {
        name: 'All',
        slug: 'all'
    },
    {
        name: 'Active',
        slug: 'active'
    },
    {
        name: 'Suspended',
        slug: 'suspended'
    },
    {
        name: 'Pending',
        slug: 'pending'
    },
]
const TEAM_FILTER = [
    { name: 'All', slug: 'all' },
    { name: 'Sell', slug: 'sell' },
    { name: 'Scan', slug: 'scan' },
    { name: 'Both scan & sell', slug: 'sell-scan' },
]
const REVIEW_FILTER = [
    { name: 'All', slug: 'all' },
    { name: 'Good', slug: 'good' },
    { name: 'Bad', slug: 'bad' },
]
const CAMPAIGN_FILTER = [
    {
        name: 'All',
        slug: 'all'
    },
    {
        name: 'Active',
        slug: 'active'
    },
    {
        name: 'Pending',
        slug: 'pending'
    },
    {
        name: 'Ended',
        slug: 'ended'
    },
    {
        name: 'Rejected',
        slug: 'rejected'
    },
]

const TEAM_ROLES = [
    { label: 'Sell', value: 'sell' },
    { label: 'Scan', value: 'scan' },
    { label: 'Both scan & sell', value: 'sell-scan' },
]

const CAMPAIGNS = [
    {
        name: 'Booster',
        price: '$10 / day',
        fee: 10,
        benefit: ['On timeline', 'user experience', 'on billboard']
    },
    {
        name: 'Starter',
        price: '$5 / day',
        fee: 5,
        benefit: ['user experience', 'on billboard']
    },
    {
        name: 'Kick Man',
        price: '$2 / day',
        fee: 2,
        benefit: ['on billboard']
    },
]

export {
    INTERESTS,
    EVENTS_TYPE,
    EVENT_FILTER,
    TEAM_ROLES,
    CAMPAIGNS,
    AMENITIES,
    SUITABLE_FOR,
    REVIEW_FILTER,
    TEAM_FILTER,
    DEFAULT_FILTER,
    VENUE_TYPES,
    CAMPAIGN_FILTER
}