import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../../../@/components/ui/dropdown-menu";
import { ppholder } from "constants/assets";
import { Button } from "../../../../@/components/ui/button";
import { EllipsisVertical } from "lucide-react";
import FeedAction from "./FeedAction";
import FeedComment from "./FeedComment";
import moment from "moment";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { uniqBy } from "lodash";
import { EmptyContainer } from "components/custom/EmptyState";
import { SkeletonCard } from "components/custom/SkeletonCards";

type FeedProp = {
  feed: any;
  feedFunc: (data: any) => void;
  delFunc: (feedId: string) => void;
  postView: boolean;
};

const FeedCard = ({ feed, feedFunc, postView, delFunc }: FeedProp) => {
  const axiosPrivate = useAxiosPrivate();
  const initPageData = {
    page: 1,
    total: 0,
  };
  const [pageData, setPageData] = useState(initPageData);
  const [allComments, setAllComments] = useState<any[]>([]);
  const [emptyState, setEmptyState] = useState(false);

  const getAllComments = async () => {
    try {
      const commentRes = await axiosPrivate.get(`/feeds/comments/${feed._id}`, {
        params: {
          page: pageData.page,
        },
      });
      const responseData = commentRes.data.data;
      setPageData({ ...pageData, total: commentRes.data.total });
      setAllComments([...allComments, ...responseData]);
      if (responseData.length === 0 && allComments.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    if (postView) {
      getAllComments();
    }
  }, [pageData.page]);

  return (
    <div
      className={`w-full bg-white rounded-2xl break-inside mb-5 cursor-pointer ${
        postView ? "" : "shadow  p-4"
      }`}
    >
      <div className="mb-3 flex justify-between items-center">
        <div className="flex justify-start items-center gap-3">
          <img
            src={
              feed.userData.profile_img !== ""
                ? feed.userData.profile_img.url
                : ppholder
            }
            alt=""
            className="w-10 h-10 rounded-xl object-cover"
          />
          <div>
            <h4 className="font-medium text-sm mb-0">
              @{feed.userData.username}
            </h4>
            <span className="text-gray-500 text-sm">
              {moment(feed.createdAt).fromNow()}
            </span>
          </div>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="overflow-hidden border-none rounded-full"
            >
              <EllipsisVertical size={15} className="text-gray-400" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              className="text-red-600 cursor-pointer"
              onClick={() => delFunc(feed._id)}
            >
              Delete Post
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div onClick={() => feedFunc(feed)}>
        <p
          className={`text-md font-normal ${
            postView ? "text-lg font-bold" : ""
          }`}
        >
          {feed.caption}
        </p>
        {feed.image !== "" ? (
          <div className="mt-5">
            <img
              src={feed.image.url}
              alt="post"
              className={`w-full rounded-lg ${
                postView
                  ? "max-h-[600px] object-scale-down"
                  : "max-h-[500px] object-cover"
              }`}
            />
          </div>
        ) : null}
        <div className="flex justify-start items-center mt-3 gap-3">
          <span className="text-sm text-gray-500 font-normal">
            {feed.likesCount} comments
          </span>
          <span className="text-sm text-gray-500 font-normal">
            {feed.commentsCount} comments
          </span>
        </div>
      </div>
      {/* <FeedAction /> */}

      {postView ? (
        <>
          <section className="bg-white antialiased mt-3">
            <div className="">
              {allComments !== undefined && allComments.length !== 0 ? (
                <div>
                  {uniqBy(allComments, "_id").map((comment) => (
                    <>
                      <FeedComment data={comment} />
                    </>
                  ))}
                </div>
              ) : allComments.length === 0 && !emptyState ? (
                <div>
                  {Array.from({ length: 4 }).map((card) => (
                    <SkeletonCard />
                  ))}
                </div>
              ) : (
                <div>
                  <EmptyContainer
                    message={"No comments yet!"}
                    instructions={`Comments will display when one is added!`}
                  />
                </div>
              )}

              {/* <form className="mt-3">
                <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200">
                  <label htmlFor="comment" className="sr-only">
                    Your comment
                  </label>
                  <textarea
                    id="comment"
                    rows={6}
                    className="resize-none px-0 w-full text-sm text-black border-0 focus:ring-0 focus:outline-none "
                    placeholder="Write a comment..."
                    required
                  ></textarea>
                </div>
                <div className="flex justify-end">
                  <Button
                    type="submit"
                    className="font-medium text-center text-white bg-black rounded-lg"
                  >
                    Comment
                  </Button>
                </div>
              </form> */}
            </div>
          </section>
        </>
      ) : null}
    </div>
  );
};

export default FeedCard;
