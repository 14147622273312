import React, { useEffect, useRef } from "react";
import { LandingFooter, LandingHeader } from "../components";
import {
  authImg,
  derrickImg,
  eventImg,
  joinImg,
  LogoAppstore,
  LogoPlaystore,
} from "constants/assets";
import { Button } from "../../@/components/ui/button";
import {
  Activity,
  Check,
  MessageSquare,
  PartyPopper,
  SquareUser,
  Ticket,
  WalletMinimal,
} from "lucide-react";
import { Separator } from "../../@/components/ui/separator";
import { Link, useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../@/components/ui/accordion";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../@/components/ui/carousel";
import { Card, CardContent } from "../../@/components/ui/card";
import { motion } from "framer-motion";
import { fadeIn } from "misc/variants";
import { getStartedBtn } from "services/misc";
import { Helmet } from "react-helmet-async";
type Props = {};

const Home = (props: Props) => {
  const pricingRef: any = useRef(null);
  const scrollToSection = (ref: any) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const features = [
    {
      icon: <Ticket size={40} strokeWidth={1.5} />,
      title: "Ticket Scanning",
      desc: "Very simple & powerful to handle any size or kind of event.",
    },
    {
      icon: <MessageSquare size={40} strokeWidth={1.5} />,
      title: "Social Engagement",
      desc: "Effective tool to help you build hype for your event.",
    },
    {
      icon: <PartyPopper size={40} strokeWidth={1.5} />,
      title: "Event Management",
      desc: "Monitor number of sales, total revenue and everything about the event.",
    },
    {
      icon: <WalletMinimal size={40} strokeWidth={1.5} />,
      title: "Secured Payments",
      desc: "Ensuring safe transactions every step of the way. Guaranteed security",
    },
  ];
  const simpleSteps = [
    {
      title: "Join Our Platform",
      desc: "Experience seamless setup in just 5 minutes. Our smooth and simple process ensures you're up and running in no time!",
      icon: <SquareUser size={60} />,
    },
    {
      title: "Manage your event",
      desc: "Select from various price packages tailored to your needs or opt for pay-per-ticket convenience. Start selling tickets today!",
      icon: <Activity size={60} />,
    },
    {
      title: "Start selling ticket",
      desc: "Easily manage payments, access comprehensive ticket sales data, and communicate directly with guests for seamless event organization and engagement.",
      icon: <Ticket size={60} />,
    },
  ];

  const faqs = [
    {
      title: `What's needed to get started on OutFun?`,
      desc: `You only need an email address and password to create an account on OutFun. That's all! This grants you access to all services on OutFun.
    No credit card details needed to start.`,
    },
    {
      title: `How do I control entry to my event?`,
      desc: `Tickets are issued with a QR code as well as a ticket number. When guests arrive at your event you scan their tickets using our free ticket scanner app to validate & check-in.`,
    },
    {
      title: `Are there any hidden fees?`,
      desc: `To use Outfun, you only need to pay for the tickets you sell. However, your payment services provider is likely to charge you a fee for your use of their services.`,
    },
    {
      title: `How do attendees pay for events?`,
      desc: `You can purchase tickets with your credit card, debit card, mobile money account etc. Paystack provides a secured payment system, so transactions are protected and safe. We don't have access to your sensitive information.`,
    },
    {
      title: `How do I get my money when event is over?`,
      desc: `Log in to your organizer dashboard. Click on the "Payout" tab and create a cashout request. We can send the money to the host's PayPal, Mobile Money account, Bank Transfer.`,
    },
  ];

  const stats = [
    {
      title: "Increased Visibility",
      desc: "Boost event exposure, attract more participants.",
    },
    {
      title: "Simplified Registration",
      desc: "Easy sign-up process for attendees.",
    },
    {
      title: "Real-time Insights",
      desc: "Access data instantly for informed decisions.",
    },
    {
      title: "Seamless Communication",
      desc: "Connect effortlessly with attendees/vendors.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>OutFun - Event Ticketing & Service Booking Platform</title>
        <meta
          name="description"
          content="Discover events, buy tickets, and book DJs, photographers, and other service providers for your events. OutFun is Ghana’s leading event platform."
        />
        <meta
          name="keywords"
          content="event booking, ticket platform, Ghana events, DJs booking, event services, concerts near me, events, event, party, nightlife"
        />
        <meta property="og:site_name" content="OutFun" />
        <meta
          property="og:description"
          content="Discover, book, and manage events seamlessly. Your all-in-one ticketing and event management solution."
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/4jSQDXF/newoflogobg.png"
        ></meta>
        <script type="application/ld+json">
          {`
            {
	"@context": "http://schema.org/",
	"@type": "Organization",
	"name": "OutFun",
	"logo": "https://i.ibb.co/4jSQDXF/newoflogobg.png",
	"url": "https://outfun.app",
	"address": {
		"@type": "PostalAddress",
		"streetAddress": "Tsokome Street",
		"addressLocality": "Accra",
		"addressRegion": "Greater Accra",
		"postalCode": "00233",
		"addressCountry": "GH"
	},
	"sameAs": [
		"https://twitter.com/outfunapp",
		"https://web.facebook.com/outfunapp",
		"https://instagram.com/outfunapp",
		"https://www.linkedin.com/company/outfun",
        "https://www.youtube.com/@outfunapp"
	]
}   
         `}
        </script>
      </Helmet>
      <div>
        <LandingHeader toRefFunc={scrollToSection} pricingRef={pricingRef} />

        <div className="w-full md:grid md:grid-cols-2 lg:grid lg:min-h-64 lg:grid-cols-2 xl:min-h-64 bg-gray-100">
          <div className="flex items-center justify-center py-10 lg:py-12">
            <div className="mx-auto w-[450px] lg:w-[500px] px-4 sm:px-8 md:pl-14 lg:p-0">
              <motion.h1
                className="lg:mb-4 mb-3 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl lg:text-6xl dark:text-white"
                // variants={fadeIn("left", 0.2)}
                // initial="hidden"
                // whileInView={"show"}
                // viewport={{ once: true, amount: 0.7 }}
              >
                <mark className="px-2 text-white bg-[#d60c0c] rounded">
                  OutFun
                </mark>{" "}
                :
                <br />
                Faster Ticketing, Endless Fun!
              </motion.h1>
              <motion.div
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
              >
                <Button
                  onClick={getStartedBtn}
                  className="bg-black hidden md:block"
                >
                  Get Started
                </Button>
              </motion.div>
            </div>
          </div>
          <div className="bg-muted lg:block px-4 md:px-0">
            <img
              src={authImg}
              alt="login"
              className="h-full rounded-lg md:rounded-none w-full object-cover dark:brightness-[0.2] dark:grayscale"
            />
          </div>
        </div>
        <div className="mx-auto px-4 max-w-screen-2xl lg:max-w-screen-2xl py-5 md:py-14 lg:py-20 md:max-w-screen-sm">
          <div className="grid grid-cols-1 max-w-6xl mx-auto gap-4 mt-9 md:grid-cols-2 lg:grid-cols-3">
            <motion.div
              variants={fadeIn("down", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="md:col-span-2  p-5 bg-black rounded-2xl text-white flex lg:px-16 flex-col justify-center"
            >
              <h3 className="font-bold text-xl md:text-4xl lg:text-5xl">
                Give your attendees <br /> a great experience
              </h3>
              <div className="mt-6 flex justify-start items-center gap-1 md:gap-3 lg:gap-3">
                <Button onClick={getStartedBtn} className="bg-white text-black">
                  Get Started
                </Button>
                <Button
                  className="border text-white border-white bg-transparent"
                  variant={"outline"}
                >
                  Connect with us
                </Button>
              </div>
            </motion.div>
            {features.map((info) => (
              <motion.div
                className="p-7 lg:p-10 bg-gray-100 rounded-2xl"
                variants={fadeIn("down", 0.3)}
                initial="true"
                whileInView={"show"}
                viewport={{ once: false, amount: 0.7 }}
                key={info.title}
              >
                {info.icon}
                <h5 className="mb-3 text-xl lg:text-2xl mt-3 font-semibold tracking-tight text-gray-900 dark:text-white">
                  {info.title}
                </h5>
                <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
                  {info.desc}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
        <div className="overflow-hidden bg-black py-5 text-3xl font-bold uppercase leading-[1.4] tracking-widest text-white">
          <div className="animate-horizontal-slide-from-right-to-left flex gap-x-[30px]">
            <span className="inline-block min-w-[200px] lg:min-w-[855px] uppercase text-sm lg:text-3xl">
              Fun Made Easier!
            </span>
            <span className="text-sm lg:text-4xl">🥳</span>
            <span className="inline-block min-w-[200px] lg:min-w-[855px] uppercase text-sm lg:text-3xl">
              Fun Made Easier!
            </span>
            <span className="text-sm lg:text-4xl">🤩</span>
            <span className="inline-block min-w-[200px] lg:min-w-[855px] uppercase text-sm lg:text-3xl">
              Fun Made Easier!
            </span>
            <span className="text-sm lg:text-4xl">🥰</span>
            <span className="inline-block min-w-[200px] lg:min-w-[855px] uppercase text-sm lg:text-3xl">
              Fun Made Easier!
            </span>
            <span className="text-sm lg:text-4xl">🎉</span>
          </div>
        </div>
        <div className="w-full py-10 lg:py-20 px-4">
          <div className="text-center mb-20">
            <h6 className="mb-4 tracking-wider text-lg">
              GET STARTED IN MINUTES
            </h6>
            <h4 className="mb-4 text-3xl lg:text-6xl font-medium underline underline-offset-3 decoration-8 decoration-[#d60c0c] leading-none tracking-tight">
              3 simple & easy step to launch
            </h4>
          </div>
          <div>
            <div className="grid grid-col-1 md:grid-cols-3 xl:grid-cols-3 max-w-screen-xl mx-auto">
              {simpleSteps.map((step) => (
                <motion.div
                  variants={fadeIn("up", 0.3)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: true, amount: 0.7 }}
                  className="max-w-sm bg-white"
                  key={step.title}
                >
                  <div className="w-full flex justify-center">{step.icon}</div>
                  <div className="p-5 text-center">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {step.title}
                    </h5>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                      {step.desc}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
        <div className="max-w-screen-sm  px-4 lg:max-w-screen-2xl md:px-20  md:max-w-screen-xl mx-auto lg:py-28 py-10 bg-gray-100">
          <div className="grid xl:grid-cols-2 gap-6">
            <motion.div
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="flex flex-col justify-center lg:me-10"
            >
              <h3 className="text-2xl md:text-4xl lg:text-6xl font-bold mb-3">
                A dedicated solution for event organisers & managers.
              </h3>
              <p className="font-regular">
                Empowering event organizers and managers with a dedicated
                solution for seamless planning and execution. Streamline your
                events effortlessly.
              </p>
              <Separator className="my-6 mt-8 bg-gray-300" />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {stats.map((sInfo) => (
                  <div key={sInfo.title}>
                    <h6 className="font-bold text-md lg:text-xl">
                      {sInfo.title}
                    </h6>
                    <p className="text-sm lg:text-lg">{sInfo.desc}</p>
                  </div>
                ))}
              </div>
            </motion.div>
            <motion.div
              variants={fadeIn("up", 0.3)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
            >
              <img
                src={eventImg}
                alt="info"
                className="w-full lg:h-[90vh] md:h-[400px] h-[200px] object-cover rounded-lg"
              />
            </motion.div>
          </div>
        </div>
        <section className="bg-white dark:bg-gray-900" ref={pricingRef}>
          <div
            className="py-12 px-4 mx-auto max-w-screen-xl md:max-w-screen-xl md:px-20 lg:py-20 lg:px-6"
            id="pricing"
          >
            <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
              <h4 className="mb-4 text-2xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                Choose Your Experience for Your Next Event.
              </h4>
              <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
                Unlock the perfect package for your event needs. Browse our
                flexible pricing options tailored to suit every budget and
                preference
              </p>
            </div>
            <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
              <motion.div
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
                className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              >
                <h3 className="mb-4 text-2xl font-semibold">Free Events</h3>
                <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                  Everything you need to start selling tickets today.
                </p>
                <div className="flex justify-center items-baseline my-8">
                  <span className="mr-2 text-xl lg:text-5xl font-extrabold">
                    Free
                  </span>
                  <span className="text-gray-500 dark:text-gray-400">
                    /no charges
                  </span>
                </div>
                <ul role="main" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    <Check className="text-[#d60c0c]" />
                    <span>No hidden monthly or setup fees.</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <Check className="text-[#d60c0c]" />
                    <span>Scan attendees for free</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <Check className="text-[#d60c0c]" />
                    <span>Quick, easy and simple to set-up.</span>
                  </li>
                </ul>
                <Link
                  to="https://forms.gle/yqWAjvvZazheooyDA"
                  target="_blank"
                  className="text-white bg-black hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
                >
                  Get started
                </Link>
              </motion.div>
              <motion.div
                variants={fadeIn("up", 0.3)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
                className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
              >
                <h3 className="mb-4 text-2xl font-semibold">Paid Events</h3>
                <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                  All in Free Package
                </p>
                <div className="flex justify-center items-baseline my-8">
                  <span className="mr-2 text-xl lg:text-5xl font-extrabold">
                    4%
                  </span>
                  <span className="text-gray-500 dark:text-gray-400">
                    of gross tickets sales
                  </span>
                </div>
                <small className="text-gray-500 mb-5">
                  Per Paid Ticket + 2.5% Payment Processing
                </small>
                <ul role="main" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    <Check className="text-[#d60c0c]" />
                    <span>Newsfeed, Social & Engagement enabled</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <Check className="text-[#d60c0c]" />
                    <span>Sell unlimited tickets at any time.</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    <Check className="text-[#d60c0c]" />
                    <span>Up to 10 ticket scanning team.</span>
                  </li>
                </ul>
                <Link
                  to="https://forms.gle/yqWAjvvZazheooyDA"
                  target="_blank"
                  className="text-white bg-black hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
                >
                  Get started
                </Link>
              </motion.div>
            </div>
          </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 md:px-20 lg:px-6">
            <h4 className="mb-8 text-2xl lg:text-4xl text-center tracking-tight font-extrabold ">
              Frequently asked questions
            </h4>

            <div>
              <Accordion type="single" collapsible className="w-full">
                {faqs.map((faq, index) => (
                  <AccordionItem value={`${index}`} key={index}>
                    <AccordionTrigger className="lg:text-md">
                      {faq.title}
                    </AccordionTrigger>
                    <AccordionContent className="lg:text-lg">
                      {faq.desc}
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </div>
        </section>
        <section>
          <div className="mx-auto px-4 max-w-screen-xl py-7 md:px-20 lg:py-20">
            <div className="text-center mb-8">
              <h4 className="mb-4 text-2xl lg:text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                TESTIMONIALS
              </h4>
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                What our customer's say about our company
              </p>
            </div>
            <Carousel className="w-full">
              <CarouselContent>
                {Array.from({ length: 1 }).map((_, index) => (
                  <CarouselItem key={index}>
                    <div className="p-1">
                      <Card>
                        <CardContent className="flex items-center justify-center p-6">
                          <figure className="max-w-screen-md mx-auto">
                            <svg
                              className="h-12 mx-auto mb-3 text-gray-400 dark:text-gray-600"
                              viewBox="0 0 24 27"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                                fill="currentColor"
                              />
                            </svg>
                            <blockquote>
                              <p className="text-md lg:text-2xl font-medium text-gray-900 dark:text-white">
                                "Fantastic platform! It made organizing my event
                                a breeze. From ticketing to communication,
                                everything was smooth and efficient"
                              </p>
                            </blockquote>
                            <figcaption className="flex items-center justify-center mt-6 space-x-3">
                              <img
                                className="w-6 h-6 rounded-full"
                                src={derrickImg}
                                alt="profile"
                              />
                              <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                <div className="pr-3 font-medium text-gray-900 dark:text-white">
                                  Derrick
                                </div>
                                <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                                  CEO at Teasar Soundz
                                </div>
                              </div>
                            </figcaption>
                          </figure>
                        </CardContent>
                      </Card>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious className="left-[3rem] top-[110%] lg:top-1/2 lg:-left-12" />
              <CarouselNext className="right-[3rem] top-[110%] lg:top-1/2 lg:-right-12" />
            </Carousel>
          </div>
        </section>
        <section className="px-5 lg:p-0">
          <div className="mx-auto max-w-screen-sm lg:max-w-screen-xl py-20">
            <div className="w-full bg-black rounded-3xl">
              <motion.div
                className="block p-5 md:flex xl:flex justify-around items-center lg:pe-20"
                variants={fadeIn("down", 0.3)}
              >
                <div className="lg:w-2/4 md:w-2/5">
                  <img src={joinImg} className="w-full lg:h-96" alt="join" />
                </div>
                <div className="lg:w-2/4 md:w-3/5">
                  <h3 className="text-2xl lg:text-4xl font-bold mb-5 text-white">
                    Join OutFun & enjoy the best benefits
                  </h3>
                  <p className="text-md lg:text-lg text-gray-400 mb-5">
                    Outfun is simple to set up, has minimum charges, allows the
                    organiser to interact with attendees in real time, keeps
                    them informed and connected with everything important for
                    them and the organiser.
                  </p>
                  <div className="flex justify-start gap-5">
                    <Button className="p-0 bg-transparent">
                      <div className="flex justify-center">
                        <img src={LogoAppstore} alt="store" className="w-40" />
                      </div>
                    </Button>
                    <Button className="p-0 bg-transparent">
                      <div className="flex justify-center">
                        <img src={LogoPlaystore} alt="store" className="w-40" />
                      </div>
                    </Button>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>
        <LandingFooter toRefFunc={scrollToSection} pricingRef={pricingRef} />
      </div>
    </>
  );
};

export default Home;
